<template>
  <div class="patient__History">
    <div class="mytabel table-sm mr-0 ml-0 p-0">
      <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
        <div
          class="col-9 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
        >
          <h5 class="d-inline mr-2 font-weight-bold">
            {{ $t("message.cities") }}
          </h5>
          <crm-refresh @c-click="refresh()"></crm-refresh>
          <div class="text-center d-flex sorddata ml-3">
            <el-input
              class="ml-3"
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
            ></el-input>
          </div>
        </div>
        <div
          class="col-3 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
        >
          <el-button
            v-can="'specialties.create'"
            size="mini"
            @click="drawer.create.status = true"
            icon="el-icon-circle-plus-outline"
          >
            {{ $t("message.create") }}
          </el-button>
        </div>
      </div>
      <table class="table table-bordered table-hover" v-loading="loadingData">
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>
        <thead>
          <tr>
            <crm-th
              :column="columns.id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.region_id"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
            <crm-th
              :column="columns.name"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>

            <crm-th
              :column="columns.settings"
              :sort="sort"
              @c-change="updateSort"
            ></crm-th>
          </tr>

          <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              >
              </el-input>
            </th>
            <th v-if="columns.region_id.show">
              <el-select
                filterable
                remote
                :remote-method="searchRegionsLoad"
                clearable
                :placeholder="columns.region_id.title"
                size="mini"
                v-model="filterForm.region_id"
              >
                <el-option
                  v-for="item in regions"
                  :key="item.name + item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </th>
            <th v-if="columns.name.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="columns.name.title"
              ></el-input>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
        </thead>

        <transition-group name="flip-list" tag="tbody">
          <tr v-for="city in list" :key="city.id" class="cursor-pointer">
            <td v-if="columns.id.show">{{ city.id }}</td>
            <td v-if="columns.region_id.show">
              {{ city.region ? city.region.name : "" }}
            </td>
            <td v-if="columns.region_id.show">
              {{ city.name }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <crm-setting-dropdown
                :model="city"
                name="regions"
                :actions="actions"
                @edit="edit"
                @delete="destroy"
              >
              </crm-setting-dropdown>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>

    <el-drawer
      size="60%" :wrapperClosable="false"
      :visible.sync="drawer.create.status"
      :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)"
      @closed="drawerClosed(drawer.create.component)"
    >
      <crm-create
        :ref="drawer.create.component"
        :drawer-name="drawer.create.name"
      ></crm-create>
    </el-drawer>

    <el-drawer
      size="60%" :wrapperClosable="false"
      :visible.sync="drawer.update.status"
      :ref="drawer.update.name"
      @opened="drawerOpened(drawer.update.component)"
      @closed="drawerClosed(drawer.update.component)"
    >
      <crm-update
        :selected="selectedModel"
        :ref="drawer.update.component"
        :drawer-name="drawer.update.name"
      >
      </crm-update>
    </el-drawer>
    <!-- end modal -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import _ from "lodash";
export default {
  mixins: [list, drawer],
  name: "regions",
  components: {
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      loadingData: false,
      selectedModel: {},
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "cities/list",
      columns: "cities/columns",
      pagination: "cities/pagination",
      filter: "cities/filter",
      sort: "cities/sort",
      regions: "regions/inventory",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() {
    if (this.regions && this.regions.length === 0) await this.loadRegions();
  },
  methods: {
    ...mapActions({
      updateList: "cities/index",
      updateSort: "cities/updateSort",
      updateFilter: "cities/updateFilter",
      updateColumn: "cities/updateColumn",
      updatePagination: "cities/updatePagination",
      editModel: "cities/show",
      empty: "cities/empty",
      delete: "cities/destroy",
      refreshData: "cities/refreshData",
      loadRegions: "regions/inventory",
    }),
    searchRegionsLoad(val) {
      this.searchRegions(val);
    },
    searchRegions: _.debounce(function (val) {
      this.loadRegions({ search: val });
    }, 500),
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedModel = model;
      this.drawer.update.status = true;
    },
    async show(model) {
      await this.showModel(model.id)
        .then((res) => {
          this.drawerShow = true;
        })
        .catch((err) => {});
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>